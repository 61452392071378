import React from "react"

const PipeflareLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="180"
            height="39"
            viewBox="0 0 180 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <g clipPath="url(#clip0_609_16)">
                <path
                    d="M41.9022 8.90253H51.4793C56.3984 8.90253 59.7376 11.6978 59.7376 16.141C59.7376 21.2192 55.9291 23.5491 51.3624 23.5491H46.9118V32.2785H41.9021V8.90253H41.9022ZM46.9119 19.5256H50.7324C53.0488 19.5256 54.6859 18.6254 54.6859 16.2386C54.6859 13.8275 52.9589 12.9259 50.8371 12.9259H46.9119V19.5256H46.9119Z"
                    fill="white"
                />
                <path
                    d="M62.0575 7.52838H66.8698V11.862H62.0575V7.52838V7.52838ZM62.0575 14.7285H66.8698V32.2785H62.0575V14.7285Z"
                    fill="white"
                />
                <path
                    d="M75.1335 30.7246V39H70.351V18.9588C70.351 17.5387 70.351 16.1364 70.3174 14.7285H74.9779C75.0451 15.1935 75.1335 16.2707 75.1335 16.9145C75.879 15.6262 77.4302 14.3218 80.1863 14.3218C84.0896 14.3218 87.1091 17.6188 87.1091 23.0627C87.1091 29.2554 83.7376 32.6856 79.5269 32.6856C77.0746 32.6856 75.7959 31.7513 75.1335 30.7247L75.1335 30.7246ZM82.2256 23.25C82.2256 20.0331 80.9363 18.2177 78.6464 18.2177C75.9136 18.2177 74.9441 19.9886 74.9441 23.5252C74.9441 26.8745 75.84 28.8234 78.4408 28.8234C80.9872 28.8234 82.2256 26.8247 82.2256 23.25Z"
                    fill="white"
                />
                <path
                    d="M93.6203 24.5425C93.6245 26.7377 94.7132 28.8828 97.1092 28.8828C99.1128 28.8828 99.6846 28.0709 100.141 27.0091H104.958C104.341 29.1574 102.443 32.6855 96.9829 32.6855C91.2582 32.6855 88.7744 28.3629 88.7744 23.7584C88.7744 18.2533 91.5724 14.3217 97.1549 14.3217C103.124 14.3217 105.154 18.6816 105.154 23.0629C105.154 23.6569 105.154 24.0422 105.09 24.5425H93.6202H93.6203ZM100.334 21.5585C100.3 19.5148 99.4914 17.7957 97.1468 17.7957C94.8409 17.7957 93.9132 19.401 93.7212 21.5585H100.334H100.334Z"
                    fill="white"
                />
                <path
                    d="M108.185 8.90253H124.344V13.0452H113.073V18.7729H123.631V22.9156H113.073V32.2785H108.185V8.90253Z"
                    fill="white"
                />
                <path
                    d="M126.46 32.2785V7.52838H131.272V32.2785H126.46Z"
                    fill="white"
                />
                <path
                    d="M149.034 27.7441C149.034 29.4152 149.168 31.8555 149.269 32.2786H144.671C144.537 31.9314 144.436 31.0063 144.428 30.5632C143.799 31.5492 142.616 32.6855 139.542 32.6855C135.352 32.6855 133.584 29.9038 133.584 27.1815C133.584 23.1834 136.763 21.3389 141.859 21.3389H144.34V20.2663C144.34 19.0186 143.895 17.7915 141.663 17.7915C139.632 17.7915 139.187 18.6632 138.965 19.9341H134.39C134.65 16.8155 136.561 14.292 141.845 14.3219C146.501 14.3514 149.034 16.1881 149.034 20.3654V27.7442L149.034 27.7441ZM144.344 24.289H142.383C139.484 24.289 138.408 25.1828 138.408 26.8749C138.408 28.1454 139.18 29.2154 140.91 29.2154C143.968 29.2154 144.344 27.069 144.344 24.7501V24.289H144.344Z"
                    fill="white"
                />
                <path
                    d="M152.55 19.489C152.55 17.7453 152.546 16.1962 152.516 14.7285H157.236C157.299 15.122 157.362 16.8636 157.362 17.8097C158.129 15.8039 159.983 14.3379 162.939 14.3218V18.9584C159.447 18.8673 157.362 19.8162 157.362 24.627V32.2785H152.55V19.489V19.489Z"
                    fill="white"
                />
                <path
                    d="M168.466 24.5425C168.471 26.7377 169.559 28.8828 171.955 28.8828C173.959 28.8828 174.531 28.0709 174.988 27.0091H179.804C179.188 29.1574 177.29 32.6855 171.829 32.6855C166.104 32.6855 163.621 28.3629 163.621 23.7584C163.621 18.2533 166.419 14.3217 172.001 14.3217C177.97 14.3217 180 18.6816 180 23.0629C180 23.6569 180 24.0422 179.937 24.5425H168.466V24.5425ZM175.18 21.5585C175.146 19.5148 174.338 17.7957 171.993 17.7957C169.687 17.7957 168.759 19.401 168.567 21.5585H175.18H175.18Z"
                    fill="white"
                />
                <path
                    d="M21.2611 5.17017C20.7432 5.07841 20.0826 5.02772 19.2578 5.02772C9.1118 5.02772 0.553075 7.34731 3.74371 0C-1.39046 2.76852 -1.91392 12.5834 6.59099 12.5834H6.59116C6.58858 12.5882 6.58548 12.5927 6.5829 12.5975H19.9667C23.5421 12.5975 26.451 15.5252 26.451 19.1235C26.451 22.7219 23.5421 25.6492 19.9667 25.6492H14.1829C12.9356 25.6492 11.9209 26.6704 11.9209 27.9258V32.0693C14.0713 33.3493 16.5784 34.0865 19.2577 34.0865C27.2309 34.0865 33.6944 27.5815 33.6944 19.5571C33.6944 12.2172 28.2855 6.15216 21.2611 5.17017V5.17017Z"
                    fill="url(#paint0_linear_609_16)"
                />
                <path
                    d="M19.9667 21.4001C21.2141 21.4001 22.2291 20.3789 22.2291 19.1234C22.2291 17.868 21.2141 16.8466 19.9667 16.8466C19.9667 16.8466 9.1954 16.8465 5.0584 16.8465C0.921397 16.8465 0.0152358 14.2864 0.0152358 14.2864C0.0152358 14.2864 -0.545422 22.5199 5.13537 22.5277C5.57282 24.6431 6.456 26.594 7.69888 28.2627V27.9258C7.69888 24.3275 10.6075 21.4001 14.1829 21.4001H19.9667V21.4001Z"
                    fill="url(#paint1_linear_609_16)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_609_16"
                    x1="14.0453"
                    y1="13.9867"
                    x2="30.9599"
                    y2="30.7934"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0996" stopColor="#A345F4" />
                    <stop offset="1" stopColor="#52009B" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_609_16"
                    x1="9.91628"
                    y1="18.1422"
                    x2="26.831"
                    y2="34.949"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0996" stopColor="#A345F4" />
                    <stop offset="1" stopColor="#52009B" />
                </linearGradient>
                <clipPath id="clip0_609_16">
                    <rect width="180" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PipeflareLogo
