import React from "react"

const HomepageHeroLinkBox: React.FC<React.SVGProps<SVGSVGElement>> = (
    props
) => {
    return (
        <svg
            width="331"
            height="226"
            viewBox="0 0 331 226"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M330.289 12.9966L325.637 7.96843L259.731 8.3123L252.279 1.5126H79.317L71.9758 8.33563H5.79316L0.711948 13.4269H0.711914V7.63125V132.39V213.003H0.711948L5.36358 218.032L71.2701 217.688L78.722 224.487H251.684L259.025 217.664H325.208L330.289 212.573H330.289V132.39V7.63125V12.9966H330.289Z"
                fill="black"
            />
            <path
                d="M165.543 226H78.3679L72.0612 219.681H49.3857V216.656H73.3118L79.618 222.975H165.543V226Z"
                fill="#DE1FA8"
            />
            <path
                d="M71.2694 223.983H35.5312V224.992H71.2694V223.983Z"
                fill="#DE1FA8"
            />
            <path
                d="M252.719 226H165.543V222.975H251.468L257.774 216.656H281.7V219.681H259.024L252.719 226Z"
                fill="#DE1FA8"
            />
            <path
                d="M295.555 223.983H259.816V224.992H295.555V223.983Z"
                fill="#DE1FA8"
            />
            <path
                d="M49.386 218.673H4.94648L0 213.716L0.711519 213.003L5.36322 217.664H49.386V218.673Z"
                fill="#DE1FA8"
            />
            <path
                d="M325.624 218.673H281.7V217.664H325.207L330.289 212.573L331 213.286L325.624 218.673Z"
                fill="#DE1FA8"
            />
            <path
                d="M165.457 0H252.632L258.939 6.31883H281.614V9.34405H257.688L251.382 3.02526H165.457V0Z"
                fill="#DE1FA8"
            />
            <path
                d="M295.469 1.00839H259.73V2.01679H295.469V1.00839Z"
                fill="#DE1FA8"
            />
            <path
                d="M78.2811 0H165.457V3.02526H79.5312L73.2255 9.34405H49.2998V6.31883H71.9754L78.2811 0Z"
                fill="#DE1FA8"
            />
            <path
                d="M71.1834 1.00839H35.4453V2.01679H71.1834V1.00839Z"
                fill="#DE1FA8"
            />
            <path
                d="M281.614 7.32724H326.054L331 12.2836L330.289 12.9966L325.637 8.33564H281.614V7.32724Z"
                fill="#DE1FA8"
            />
            <path
                d="M5.37601 7.32724H49.2999V8.33564H5.79276L0.711519 13.4269L0 12.7139L5.37601 7.32724Z"
                fill="#DE1FA8"
            />
        </svg>
    )
}

export default HomepageHeroLinkBox
