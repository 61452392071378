import React from "react"

const DiscordLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                d="M71.156 14.7973C65.7185 12.2533 59.9045 10.4044 53.8261 9.35156C53.0796 10.7012 52.2075 12.5165 51.6062 13.9605C45.1447 12.9888 38.7427 12.9888 32.4001 13.9605C31.7989 12.5165 30.9069 10.7012 30.1538 9.35156C24.0688 10.4044 18.2481 12.2601 12.8107 14.8108C1.84325 31.384 -1.12984 47.5456 0.356708 63.4777C7.63088 68.9098 14.6804 72.2097 21.611 74.3691C23.3222 72.014 24.8484 69.5104 26.1631 66.872C23.6591 65.9205 21.2609 64.7463 18.9948 63.3832C19.5959 62.9379 20.184 62.4722 20.7521 61.9931C34.5737 68.4578 49.5911 68.4578 63.2476 61.9931C63.8224 62.4722 64.4103 62.9379 65.005 63.3832C62.7322 64.753 60.3273 65.9272 57.8233 66.8788C59.1381 69.5104 60.6577 72.0208 62.3755 74.3757C69.3127 72.2164 76.3688 68.9166 83.643 63.4777C85.3872 45.0083 80.6633 28.9952 71.156 14.7973ZM28.0461 53.6796C23.897 53.6796 20.4944 49.8061 20.4944 45.0893C20.4944 40.3724 23.8244 36.4923 28.0461 36.4923C32.268 36.4923 35.6705 40.3656 35.5978 45.0893C35.6044 49.8061 32.268 53.6796 28.0461 53.6796ZM55.9536 53.6796C51.8045 53.6796 48.4019 49.8061 48.4019 45.0893C48.4019 40.3724 51.7317 36.4923 55.9536 36.4923C60.1753 36.4923 63.5779 40.3656 63.5053 45.0893C63.5053 49.8061 60.1753 53.6796 55.9536 53.6796Z"
                fill="white"
            />
        </svg>
    )
}

export default DiscordLogo
