import React from "react"

const TokenmintLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            width="166"
            height="32"
            viewBox="0 0 166 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <g clipPath="url(#clip0_609_31)">
                <path
                    d="M6.82638 1.43405C7.3467 0.99855 7.9327 0.630464 8.55124 0.340942H0V3.97242H4.8883C5.40587 2.82489 6.15258 1.99804 6.82638 1.43405Z"
                    fill="white"
                />
                <path
                    d="M15.5998 0.340942H12.4242C9.68259 0.340942 5.71796 2.4846 5.71796 7.12949C6.74177 3.95204 8.97786 3.97242 10.9021 3.97242H15.5998V0.340942H15.5998Z"
                    fill="#26DB8D"
                />
                <path
                    d="M5.71796 7.12953V19.7187H9.91121V3.9873C8.25943 4.0574 6.56947 4.48682 5.71796 7.12953V7.12953Z"
                    fill="white"
                />
                <path
                    d="M26.8309 0C32.7542 0 37.0644 4.18364 37.0644 10.0229C37.0644 15.8622 32.7542 20.0461 26.8309 20.0461C20.937 20.0461 16.6268 15.8625 16.6268 10.0229C16.6268 4.18335 20.937 0 26.8309 0ZM26.8309 3.66053C23.2243 3.66053 20.9077 6.39141 20.9077 10.0229C20.9077 13.6256 23.2243 16.3856 26.8309 16.3856C30.4376 16.3856 32.7835 13.6256 32.7835 10.0229C32.7835 6.39141 30.4376 3.66053 26.8309 3.66053V3.66053Z"
                    fill="white"
                />
                <path
                    d="M45.6862 11.9403L44.1616 13.7415V19.6974H39.9977V0.319641H44.1616V8.97729L51.0815 0.319641H56.2131L48.4425 9.41287L56.7407 19.6974H51.6095L45.6862 11.9403Z"
                    fill="white"
                />
                <path
                    d="M58.6474 0.319641H72.4875V3.86396H62.8113V8.07636H72.2822V11.621H62.8113V16.124H72.4875V19.6974H58.6474V0.319641Z"
                    fill="white"
                />
                <path
                    d="M79.9067 6.68204V19.6974H75.7431V0.319641H80.024L89.0849 12.87V0.319641H93.2485V19.6974H89.2316L79.9067 6.68199V6.68204Z"
                    fill="white"
                />
                <path
                    d="M114.127 5.75235L108.614 19.6974H106.796L101.283 5.75235V19.6974H97.1198V0.319641H102.955L107.705 12.4053L112.455 0.319641H118.32V19.6974H114.127V5.75235H114.127Z"
                    fill="white"
                />
                <path
                    d="M122.192 0.319641H126.355V19.6974H122.192V0.319641Z"
                    fill="white"
                />
                <path
                    d="M134.39 6.68204V19.6974H130.226V0.319641H134.507L143.568 12.87V0.319641H147.731V19.6974H143.714L134.39 6.68199V6.68204Z"
                    fill="white"
                />
                <path
                    d="M156.118 3.95116H150.4V0.319641H166V3.95116H160.311V19.6974H156.118V3.95116H156.118Z"
                    fill="white"
                />
                <path
                    d="M89.6151 24.5591H93.4974C94.9021 24.5591 95.621 25.447 95.621 26.422C95.621 27.3427 95.0457 27.9564 94.3489 28.0988C95.1341 28.2195 95.7646 28.9756 95.7646 29.8959C95.7646 30.9918 95.0236 31.8686 93.6189 31.8686H89.615V24.5591H89.6151ZM93.1544 27.4963C93.6852 27.4963 94.017 27.1565 94.017 26.6851C94.017 26.2359 93.6852 25.8852 93.1544 25.8852H91.1855V27.4963H93.1544ZM93.2097 30.5426C93.8071 30.5426 94.161 30.2028 94.161 29.677C94.161 29.2164 93.8292 28.822 93.2097 28.822H91.1855V30.5425H93.2097V30.5426Z"
                    fill="white"
                />
                <path
                    d="M99.5015 28.8767L96.659 24.5591H98.4508L100.287 27.4963L102.101 24.5591H103.893L101.072 28.8767V31.8686H99.5015V28.8767V28.8767Z"
                    fill="white"
                />
                <path
                    d="M114.121 28.8001H110.637V31.8686H109.067V24.5591H110.637V27.4303H114.121V24.5591H115.703V31.8686H114.121V28.8001Z"
                    fill="white"
                />
                <path
                    d="M121.475 24.4387C123.709 24.4387 125.335 26.0166 125.335 28.2195C125.335 30.4225 123.709 32 121.475 32C119.252 32 117.626 30.4221 117.626 28.2195C117.626 26.0169 119.252 24.4387 121.475 24.4387ZM121.475 25.8195C120.115 25.8195 119.241 26.8497 119.241 28.2196C119.241 29.5781 120.115 30.6192 121.475 30.6192C122.836 30.6192 123.72 29.5781 123.72 28.2196C123.72 26.8497 122.836 25.8195 121.475 25.8195Z"
                    fill="white"
                />
                <path
                    d="M129.99 29.2497H128.84V31.8686H127.269V24.5591H130.72C132.257 24.5591 133.198 25.5565 133.198 26.9154C133.198 28.2086 132.379 28.899 131.583 29.0851L133.242 31.8686H131.439L129.99 29.2497ZM130.488 25.8962H128.84V27.9126H130.488C131.118 27.9126 131.594 27.5182 131.594 26.9044C131.594 26.2906 131.118 25.8961 130.488 25.8961V25.8962Z"
                    fill="white"
                />
                <path
                    d="M135.209 24.5591H136.78V31.8686H135.209V24.5591Z"
                    fill="white"
                />
                <path
                    d="M138.803 30.6082L142.287 25.929H138.803V24.5591H144.344V25.8086L140.86 30.4987H144.421V31.8686H138.803V30.6082Z"
                    fill="white"
                />
                <path
                    d="M146.444 24.5592H151.665V25.8962H148.015V27.485H151.588V28.822H148.015V30.5207H151.665V31.8686H146.444V24.5591V24.5592Z"
                    fill="white"
                />
                <path
                    d="M155.291 26.9592V31.8686H153.72V24.5591H155.335L158.753 29.2934V24.5591H160.324V31.8686H158.808L155.291 26.9592V26.9592Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_609_31">
                    <rect width="166" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TokenmintLogo
